<template>
  <div>
    <div class="headerbut">
      <el-button class="seniorbut" @click="submitbut()">提交</el-button>
      <el-button class="ordinarybut" @click="clearBtn()">清空</el-button>
    </div>
    <div class="panel">
      <div class="textbox">
        <div>序号</div>
        <div>转单号</div>
        <div>计费重量</div>
        <div>应付金额</div>
        <div>备注</div>
      </div>
      <el-input type="textarea" rows="20" v-model="textareaVal"> </el-input>
    </div>
    <div class="headerbut">请将应付明细粘贴到录入面板</div>
  </div>
</template>
<script>
import { Api } from "@/assets/js/api";
import tools from '@/assets/js/tool'
export default {
  inject: ["reload"],
  data() {
    return {
      textareaVal: "",
    };
  },
  mounted() {},
  methods: {
    clearBtn(){
      this.$confirm('是否清空粘贴版内容?','提示:').then(()=>{
        this.textareaVal = '';
      }).catch(()=>{

      })
    },
    //提交
    submitbut() {
      let that = this;
      let textarea = this.textareaVal;
      var String = [];
      String.push(textarea);

      let datsArr = String[0].split("\n") || [];
      if (datsArr.length <= 0) {
        return;
      }

      let flag = /\s/; //所有空白字符
      let newArr = []; //切割后的数组
      for (var i = 0; i < datsArr.length; i++) {
        if (datsArr[i]) {
          let strArr = datsArr[i].split(flag);
          let arr1 = [];
          for (var z = 0; z < strArr.length; z++) {
            if (strArr[z]) {
              arr1.push(strArr[z]);
            }
          }
          newArr.push(arr1);
        }
      }
      if (newArr.length == 0) {
        return;
      }
      console.log(newArr);
      let pArr = [];
      for (var a = 0; a < newArr.length; a++) {
        let bArr = newArr[a] || [];
        var obj = {
          channelNumber: bArr[1] || "",
          importBillingWeight: bArr[2] || "",
          settlementAmount: bArr[3] || "",
          comment: bArr[4] || "",
        };
        pArr.push(obj);
      }
      console.log(pArr);

      Api.importCost({
        infoReq: pArr,
      }).then((res) => {
        that.$message.success(res.data.message || "");
        setTimeout(() => {
          tools.closePage('/PayableManagement/Import');
          that.reload();
        }, 1000);
      });

      //    "infoReq": [
      //   {
      //     "channelNumber": "",
      //     "comment": "",
      //     "importBillingWeight": "",
      //     "settlementAmount": ""
      //   }
      // ]
    },
  },
};
</script>
<style scoped lang="scss">
.headerbut {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 14px;
}
.panel {
  width: 100%;
  margin: 20px auto;

  box-sizing: border-box;
  .textbox {
    width: 60%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
}

.box2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  margin: 0 auto;
  display: flex;
  margin: 10px auto;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  .remarksbox {
    color: #606266;
    width: 50%;
    display: flex;
    padding: 5px 1%;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    .remarkstitle {
      width: 7%;
      line-height: 30px;
    }
    .remarksinp {
      width: 50%;
    }
  }
}
.queryItem1:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}
//能输入样式
.queryItem1 {
  min-width: 455px;
  width: 30%;
  margin: 3px 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;

  align-items: center;
  font-size: 14px;
  .FontStyle {
    color: #f00;
    cursor: pointer;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .queryLabel {
    width: 100px;
    min-width: 100px;
    color: #606266;
    text-align: right;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .right_inpu_post {
    position: relative;
    .niming {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 99;
    }
  }
  .right_inpu ::v-deep {
    .el-input__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 100%;
    }
  }
  .right_inpu ::v-deep {
    .el-textarea__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 375px;
    }
  }
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
</style>